import React from 'react'
import deliver from '/static/images/home/home_bar.webp'
import WideCard from 'components/common/wideCard'

const Experience = () => {
  return (
    <div className="w-full flex flex-col gap-10">
      <WideCard
        title="The Way Applications Should be Delivered"
        imgSrc={deliver}
        imgAlt="Mlytics platform delivers 50+ PB"
        paragraphTitle="Mlytics platform delivers 50+ PB per month."
        paragraphContent=""
        actionLink="/platform/global-network"
        actionText="Learn Our Experiences"
      />
    </div>
  )
}

export default Experience
