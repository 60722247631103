import React from 'react'
import { graphql } from 'gatsby'
import Layout from 'layouts/index'
import Seo from 'components/seo'
import Cover from 'components/home/homeCover'
import UseCase from 'components/common/useCase'
import Feature from 'components/home/feature'
import Experience from 'components/home/experience'

const IndexPage = () => {
  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: 'Mlytics - Seamlessly Distribute Your Applications',
            metaDescription:
              "Optimize the global delivery of your applications with Mlytics' cutting-edge platform. Enhance performance, reliability, and reach—scale your digital assets worldwide today!",
            metaUrl: 'https://www.mlytics.com/',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2023/03/og_v5.jpg',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />

        {/* Hero Cover Section */}
        <section
          style={{
            height: 'calc(100vh - 100px)',
            minHeight: '600px'
          }}
          className="px-9 md:px-[86px] mb-5 lg:max-h-[800px] base:absolute base:left-1/2 base:-translate-x-1/2 base:w-screen base:max-w-[1920px]"
        >
          <Cover />
        </section>

        <div className="hidden mb-5 lg:max-h-[800px] base:block base:h-[calc(100vh-100px)] base:min-h-[600px]"></div>

        {/* Feature Section */}
        <section className="py-[60px] px-9 md:px-[86px]">
          <Feature />
        </section>

        {/* Use Case Section */}
        <section className="py-[60px]">
          <UseCase />
        </section>

        {/* Experience Section */}
        <section className="relative py-[60px] flex flex-col">
          <div className="static z-[1] px-9 md:px-[86px] w-full">
            <Experience />
          </div>
          {/* gradient black shadow */}
          {/* <div className="absolute z-[0] top-[50%] min-h-[240px] h-[50%] w-full bg-gradient-to-b from-dark-100 to-black-100" /> */}
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
