import React from 'react'
import hero from '/static/images/home/home_hero.webp'
import Cover from 'components/common/cover'

const HomeCover = () => {
  return (
    <Cover
      title="Seamlessly Distribute Your Application. Anywhere. Any Scale."
      imgSrc={hero}
      imgAlt="Home Hero Picture"
      paragraphContent="Mlytics Platform lets you seamlessly manage and distribute digital applications and assets - such as AI generated content, software updates, or premium media at global scale."
      actionLinkOne="/contact-us"
      actionTextOne="Start Your Journey"
      actionLinkTwo="/platform/overview"
      actionTextTwo="Discover More"
    />
  )
}

export default HomeCover
