import React from 'react'
import { Link } from 'gatsby'
import challenge from '/static/images/home/home_card_1.webp'
import building from '/static/images/home/home_card_2.webp'

const Feature = () => {
  return (
    <div className="flex flex-col md:flex-row items-center justify-center gap-9">
      <div className="relative flex-1 py-10 px-5 flex flex-col self-stretch items-center justify-center gap-10 group">
        <div className="absolute z-[0] inset-0 rounded-2xl overflow-hidden">
          <img
            src={challenge}
            alt="From Challenge to Solution"
            className="w-full h-full object-cover hover:scale-110 transition-all duration-200 ease-linear"
          />
        </div>
        <div className="static z-[1] pointer-events-none">
          <h3 className="h3-card-title text-white">From Challenge to Solution</h3>
          <p className="h4-content-text text-white text-center">See how Mlytics can help</p>
        </div>
        <Link
          to="/use-case/hyper-speed-files-delivery"
          className="h4-content-text btn-white-shadow px-[18px] py-3 rounded static z-[1] pointer-events-auto"
        >
          Explore Use Cases
        </Link>
      </div>
      <div className="relative flex-1 py-10 px-5 flex flex-col self-stretch items-center justify-center gap-10 group">
        <div className="absolute z-[0] inset-0 rounded-2xl overflow-hidden">
          <img
            src={building}
            alt="Build API with Mlytics"
            className="w-full h-full object-cover hover:scale-110 transition-all duration-200 ease-linear"
          />
        </div>
        <div className="static z-[1] pointer-events-none">
          <h3 className="h3-card-title text-white">Start Building Now</h3>
          <p className="h4-content-text text-white text-center">Let's power your applications</p>
        </div>
        <a
          href="https://developer.mlytics.com/v2.0-MPI/docs/getting-started"
          target="_blank"
          className="block h4-content-text btn-white-shadow px-[18px] py-3 rounded static z-[1] pointer-events-auto"
          rel="noreferrer"
        >
          Read API Docs
        </a>
      </div>
    </div>
  )
}

export default Feature
